<template>
  <div id="app">
    <NavigationBar>
    </NavigationBar>
    <router-view />
    <Backtop></Backtop>
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import Backtop from './components/Backtop.vue';
export default {
  name: 'App',
  components: {
    NavigationBar,
    Backtop
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* width: 100%; */
}

@media (max-width: 600px) {
  #app{
    width: 1200px;
    height: 2200px;
  }
 
}
</style>
