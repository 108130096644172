<template>
  <div>
    <div class="message_icon">
      <div class="iconfont_box" @mouseover="showPhone" @mouseleave="hide">
        <img src="@/assets/images/dianhua.svg" />
      </div>
      <div class="iconfont_box" @mouseover="showQRCode" @mouseleave="hide">
        <img src="@/assets/images/weixin.svg" />
      </div>
    </div>

    <div class="phone" v-if="isPhoneVisible">
      <p>13671305434</p>
    </div>

    <div class="qr-code" v-if="isQRCodeVisible">
      <img src="@/assets/images/qiaoqiao.jpg" alt="QR Code" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPhoneVisible: false,
      isQRCodeVisible: false
    }
  },
  methods: {
    showPhone() {
      this.isPhoneVisible = true
      this.isQRCodeVisible = false
    },
    showQRCode() {
      this.isQRCodeVisible = true
      this.isPhoneVisible = false
    },
    hide() {
      this.isPhoneVisible = false
      this.isQRCodeVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.message_icon {
  position: relative;
  width: 55px;
  height: 110px;
  background-color: #fff;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  padding: 0 7px;
  cursor: pointer;

  .iconfont_box {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .iconfont_box:nth-child(1) {
    border-bottom: 1px solid #d3d3d3;
  }
}

.phone {
  position: absolute;
  top: -60px;
  right: 60px;
  z-index: 1000;
  width: 140px;
  height: 45px;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 45px;
  transition: all 0.3s ease-in-out;
}

.qr-code {
  position: absolute;
  top: -60px;
  right: 60px;
  z-index: 1000;
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    border-radius: 10px;
  }
}
</style>
