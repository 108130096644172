export default {
    inserted: function (el, binding) {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                binding.value();
                observer.unobserve(el);
            }
        });
        observer.observe(el);
    }
};