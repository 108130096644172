import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/safe.scss'
import "swiper/css/swiper.min.css"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import lazyLoad from './directives/lazyLoad';
Vue.directive('lazy-load', lazyLoad);
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
