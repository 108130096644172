import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/product',
    component: () => import('@/views/product/index.vue')
  },
  {
    path: '/usaco',
    component: () => import('@/views/usaco/index.vue')
  },
  {
    path: '/download',
    component: () => import('@/views/download/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
