<template>
    <div>
        <el-backtop class="backTop">
            <div>
                <ContactCode></ContactCode>
                <div class="backTop-btn">
                    <img src="@/assets/images/zhiding.svg" />
                </div>
            </div>
        </el-backtop>
    </div>
</template>
<script>
import ContactCode from './ContactCode.vue'
export default {
    components: {
        ContactCode
    },
}
</script>
<style lang="scss" scoped>
::v-deep .el-backtop {
    right: 0;
    position: fixed;
    right: 0;
    top: 50%;
}

.backTop {
    .backTop-btn {
        margin-top: 10px;
        width: 55px;
        height: 55px;
        background-color: #fff;
        box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
        }
    }

}
</style>